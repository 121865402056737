// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MuiColorInput } from 'mui-color-input'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";
import { wsCardList } from "actions/webserviceActions";
import { connect } from 'react-redux'
import { PropTypes } from "prop-types";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import MDButton from "components/MDButton";
import { Button, Icon, TextField, Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { FormControl } from '@mui/base/FormControl';
import { wsCardSave } from "actions/webserviceActions";
import { wsCardDelete } from "actions/webserviceActions";
import { ModalContent, Modal, StyledBackdrop } from "components/Modal";
import { wsCardDeleteMemRelease } from "actions/webserviceActions";
import { wsCardSaveMemRelease } from "actions/webserviceActions";
import defaultProfile from 'assets/images/default-profile.svg'
import addImage from 'assets/images/add-image.svg'
import shareIcon from 'assets/images/share.svg'
import { resizeBase64Img } from "utils/helpers";
import linearGradient from "assets/theme/functions/linearGradient";
import BusinessCard from "components/BusinessCard";

function Cards({ dispatch, cardList, cardSave, cardDelete }) {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [imageFile, setImageFile] = useState("")
  const [phoneError, setPhoneError] = useState("");
  const [positionError, setPositionError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false);
  const [cardData, setCardData] = useState({ name: "" })
  const bgColor = linearGradient("#EE5B5B", "#CD2F2F", 135)
  const handleOpen = () => { setNameError(""); setOpen(true) };
  const handleClose = () => { setNameError(""); setOpen(false) };
  const columns = [{ Header: "Wizytówka", accessor: "preview", width: "45%", align: "left" },
  { Header: "Akcja", accessor: "actions", align: "left" }]

  const onEdit = (item) => {
    setCardData({ ...item })
    if(item.photo_url != null){
      setImageFile(item.photo_url)
    } else {
      setImageFile("")
    }
    handleOpen()
  }

  const onDelete = (item) => {
    dispatch(wsCardDelete({ id: "global" }, item.id))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCardData(values => ({ ...values, [name]: value }))
  }

  useEffect(() => {
    dispatch(wsCardList({ id: "global" }))
  }, []);

  useEffect(() => {
    if (fetchIsFulfilled(cardList)) {
      let rows = []
      if (cardList.data != null && cardList.data.length) {
        cardList.data.forEach(card => {
          rows.push({ 
            ...card,
            preview: <BusinessCard card={card} checkbox={false} />,
            actions: (<><Button onClick={() => onEdit(card)}>Edycja</Button><Button onClick={() => onDelete(card)}>Usuń</Button></>) })
        })
      }
      setRows(rows)
    }
  }, [cardList.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardSave)) {
      dispatch(wsCardList({ id: "global" }))
      dispatch(wsCardSaveMemRelease({ id: "global" }))
      handleClose()
    }
  }, [cardSave.data]);

  useEffect(() => {
    if (fetchIsFulfilled(cardDelete)) {
      dispatch(wsCardList({ id: "global" }))
      dispatch(wsCardDeleteMemRelease({ id: "global" }))
    }
  }, [cardDelete.data]);

  const handleCaptureFile = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.accept.includes('image') ? 'images' : 'videos';

    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
       /*  this.setState((prevState) => ({
            [name]: [...prevState[name], e.target.result]
        })); */
        
        resizeBase64Img(e.target.result, 100, 100).then((result)=>{
          setImageFile(result)
        });
        
    };
};

  const onSubmit = (e) => {
    e.preventDefault();
    /* if (cardData.name == null || cardData.name === "") {
      setNameError("Nazwa projektu nie może być pusta")
    } else { */
    dispatch(wsCardSave({ id: "global" }, { ...cardData, photo: imageFile }))
    //}
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Moje wizytówki
                </MDTypography>
                <MDButton variant="gradient" color="dark" onClick={() => { handleOpen(); setCardData({ name: "" }); }}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;dodaj
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose}
                  style={{overflowY: "scroll", top: 10}}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent sx={{ width: 400 }} style={{marginTop: 55}}>
                    <h2 id="unstyled-modal-title" className="modal-title" style={{ marginBottom: 20 }}>
                      {cardData.id != null ? "Edytuj" : "Dodaj"} wizytówkę
                    </h2>
                    <FormControl defaultValue="" required >
                      <input
                        accept="image/*"
                        //className={classes.input}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        onChange={handleCaptureFile}
                        type="file"
                      />
                      
                      <label htmlFor="raised-button-file" cursor="pointer">
                        <MDBox component="img" border="1px solid transparent" bgColor={imageFile !== "" ? "transparent" : "#EE5B5B"} borderRadius="50px" src={imageFile !== "" ? imageFile : addImage} alt="Zdjęcie profilowe" width="70px" height="70px" marginBottom="10px" />
                        {/* <Button variant="raised" component="span">
                          Wybierz
                        </Button> */}
                      </label>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Imię"
                        error={nameError}
                        helperText={nameError}
                        name="name"
                        value={cardData.name}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Email"
                        error={emailError}
                        helperText={emailError}
                        name="email"
                        value={cardData.email}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Telefon"
                        error={phoneError}
                        helperText={phoneError}
                        name="phone"
                        value={cardData.phone}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Stanowisko"
                        error={positionError}
                        helperText={positionError}
                        name="job_position"
                        value={cardData.job_position}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Firma"
                        error={companyError}
                        helperText={companyError}
                        name="company_name"
                        value={cardData.company_name}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="NIP"
                        name="tax_number"
                        value={cardData.tax_number}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="Adres"
                        error={addressError}
                        helperText={addressError}
                        name="address"
                        value={cardData.address}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        label="URL"
                        error={urlError}
                        helperText={urlError}
                        name="site_url"
                        value={cardData.site_url}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        multiline
                        label="Dodatkowe informacje"
                        name="additional_info"
                        value={cardData.additional_info}
                        variant="outlined"
                        onChange={handleChange}
                      />
                      <div style={{ height: 20 }}></div>
                      <MDButton variant="gradient" color="dark" onClick={onSubmit} >
                        <Icon sx={{ fontWeight: "bold" }}>save</Icon>
                        &nbsp;zapisz
                      </MDButton>
                    </FormControl>
                  </ModalContent>
                </Modal>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

Cards.propTypes = {
  dispatch: PropTypes.func,
  cardList: PropTypes.object,
  cardSave: PropTypes.object,
  cardDelete: PropTypes.object
}
export default connect(store => {
  let cardList = (store.webservice.cardList != null && store.webservice.cardList.length) ? store.webservice.cardList[0] : []
  let cardSave = (store.webservice.cardSave != null && store.webservice.cardSave.length) ? store.webservice.cardSave[0] : []
  let cardDelete = (store.webservice.cardDelete != null && store.webservice.cardDelete.length) ? store.webservice.cardDelete[0] : []
  return { cardList, cardSave, cardDelete }
})(Cards);
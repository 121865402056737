import { apiUrl } from 'helpers/const';
import store from '../store'

//const localizedString = (store != null && store.getState().desktopUI != null) ? store.getState().desktopUI.localizedString : {}

//Mock abort controller in tests
const AbortController = (global.AbortController != null) ? global.AbortController : function () { this.signal = undefined; this.abort = x => x }

function restFetchHelper(method, restUrl, restData, signal) {
    let fetchAddress = apiUrl
    let token = sessionStorage.getItem('token')
    //token = "Bearer 901e5a2c3f9e0e5db6b91c372bc35ca5cad6534506a09c499f2baf3a4ab579d0"
    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if(token != null){
      headers.Authorization = token
    }
    return fetch(fetchAddress + restUrl, {
      headers,
      method,
      body: (method !== "GET") ? JSON.stringify(restData) : null,
      credentials: 'include',
      signal
    })
      .then(response => {
        for (const pair of response.headers.entries()) {
          if(pair[0] === 'authorization'){
            sessionStorage.setItem('token', pair[1]);
          }
       }
        if (!response.ok) {
          return response.text().then((text) => {
            response.text = text
            return Promise.reject(response)
          })
        }
        /* return response.text().then(text => {
          let responseText = getCorrectResponse(text)
          return Promise.resolve(responseText)
        }) */

        return response.json()
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = response.text; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        return Promise.reject(response)
      })
}

export function userLogIn(email, password) {
  const restUrl = "account/login"
  const restData = { email, password }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, restData, abortController.signal)
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userLogOut() {
  const restUrl = "account/logout"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function rightsList(userId) {
  const restUrl = "rights/" + userId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userRemindPassword(username, sendto) {
  const restUrl = "users/reminds/"
  const restData = { username, sendto }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, restData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userLoggedIn() {
  const restUrl = "users/logged/in"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userInfo() {
  const restUrl = "users/info"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function accountList() {
  const restUrl = "accounts/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function accountChangePassword(old_password, new_password) {
  const restUrl = "account/change_password"
  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, {old_password, new_password}, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function accountUpdate(accountData) {
  const restUrl = "account/"+accountData.account_id
  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, {...accountData}, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardList() {
  const restUrl = "card/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardSave(cardData) {
  let restUrl = "card/"
  if(cardData.id != null){
    restUrl = "card/"+cardData.id
  }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, cardData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardDelete(cardId) {
  const restUrl = "card/"+cardId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("DELETE", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardGroupList() {
  const restUrl = "card_group/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardGroupDelete(cardGroupId) {
  const restUrl = "card_group/"+cardGroupId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("DELETE", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardGroupSave(cardGroupData) {
  let restUrl = "card_group/"
  if(cardGroupData.id != null){
    restUrl = "card_group/"+cardGroupData.id
  }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, cardGroupData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardProjectList() {
  const restUrl = "card_project/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardProjectSave(cardProjectData) {
  let restUrl = "card_project/"
  if(cardProjectData.id != null){
    restUrl = "card_project/"+cardProjectData.id
  }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, cardProjectData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardProjectDelete(cardProjectId) {
  const restUrl = "card_project/"+cardProjectId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("DELETE", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function cardProjectAssignment(cardProjectId, cardIds) {
  const restUrl = "card_project/assignment/"+cardProjectId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, cardIds, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function scannedCardList() {
  const restUrl = "scanned_card/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function scannedCardDelete(cardIds) {
  const restUrl = "scanned_card/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("DELETE", restUrl, cardIds, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function scannedCardGroupAssignment(group_id, card_ids) {
  const restUrl = "scanned_card/group_assignment_simple"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, {group_id, card_ids}, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function linkList() {
  const restUrl = "link/"

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function linkSave(linkData) {
  let restUrl = "link/"
  if(linkData.id != null){
    restUrl = "link/"+linkData.id
  }

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("POST", restUrl, linkData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function linkDelete(linkId) {
  const restUrl = "link/"+linkId

  const abortController = new AbortController()
  const fetchPromise = () => restFetchHelper("DELETE", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}
import { useState, useEffect, useMemo } from "react";
import {connect} from 'react-redux'
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";
import {PropTypes} from 'prop-types'

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import store from "store";
import SignIn from './layouts/authentication/sign-in/index'
import SignUp from './layouts/authentication/sign-up/index'
import NewPassword from './layouts/authentication/new-password/index'
import ResetPassword from './layouts/authentication/reset-password/cover/index'
import CardData from './layouts/cardData/index'
import CardDataWithLinks from "layouts/cardDataWithLinks";
import PublicProfile from "layouts/publicProfile";

export function App(props) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false)
  const { pathname } = useLocation();

  //let loggedIn = false
    if(store != null && store.getState() != null && store.getState().webservice != null && store.getState().webservice.loggedIn){
      //setLoggedIn(store.getState().webservice.loggedIn)
    }

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const cardId = queryParameters.get("id")
  }, [])

  useEffect(() => {
    setLoggedIn(props.loggedIn)
  }, [props.loggedIn])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
    const queryParameters = new URLSearchParams(window.location.search)
    const cardId = queryParameters.get("id")
    const profileId = queryParameters.get("profile")
  return <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {loggedIn && layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="E-wizytówki"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/* <Configurator />
          {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      {profileId != null ? <Routes><Route path="*" element={<PublicProfile />} key={"profile-data"} /></Routes> :
      cardId != null ? <Routes><Route path="*" element={<CardDataWithLinks />} key={"card-data"} /></Routes> :
      loggedIn ? <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/scanned-cards" />} />
      </Routes> : <><Routes>
        <Route exact path={"authentication/sign-up"} element={<SignUp />} key={"sign-up"} />
        <Route exact path={"authentication/sign-in"} element={<SignIn />} key={"sign-in"} />
        <Route exact path={"/new-password"} element={<NewPassword />} key={"new-password"} />
        <Route exact path={"authentication/reset-password"} element={<ResetPassword />} key={"reset-password"} />
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
      </>}
      <ToastContainer />
    </ThemeProvider>
  
}
App.propTypes = {
  loggedIn: PropTypes.boolean
}

const mapStateToProps = (store) => ({
  loggedIn: store.webservice.loggedIn,
});
export default connect(mapStateToProps, null)(App);